.marg-left-10 {
    margin-left: 10px;
}
.marg-up-10 {
    margin-top: 10px;
}
.red-alert {
    color: red;
}

.warning-alert {
    color: orange;
}
