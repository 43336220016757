.text {
  display: flex;
  flex-wrap: wrap;
}

.text h6,
.text p {
  margin-bottom: 0;
  flex: 0 0 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}
.imgContainer {
  display: flex;
  justify-content: space-between;
}
.imgSize {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.del_img {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  justify-content: space-between;
}

.del_img .del_bor {
  background: #fff;
  border-radius: 5px;
  padding: 2px 9px;
  cursor: pointer;
}

.del_img .del_bor:hover {
  background: rgb(0 0 0 / 0.1);
}

.pravatar {
  height: 35px;
  width: 35px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.centered {
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.logo-cont{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  width: 100%;
  padding: 5px;
  border-radius:5px;
  border: solid 1px #26A3DB;
  position: relative;
  
}
.logo-btn{
  width: 100px;
  background:#26A3DB;
  height: 100%;
  position: absolute;
  top:0;
  left: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: center;
  color: #fff; 
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.logo-cont > span{
  margin-left: 100px;
}

.form-img{
  width: 50px;
  padding-left: 5px !important;
}

.input-container-subdiv{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.roomname_heading{
  font-size: 1rem !important;
  width: 100%;
}