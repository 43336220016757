.chart-container-doughnut {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

 .mdl-tbl-dnt,.tbl {
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 auto;
  border: 1px solid #eee;
  color: #111;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 55%; 
}

.mdl-tbl-dnt > thead {
  background-color: #3498db;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  width: 100%;
  display: block;
}
.violated-apps-table > thead tr th{
  padding: 20px ;
  width: 30%;
  font-size: 1rem;
  text-transform: uppercase;
}

.mdl-tbl-dnt > thead tr th:nth-child(3),.mdl-tbl-dnt > thead tr th:nth-child(4){
  padding-left: 18px;
}




.violated-apps-table > tbody tr td{
  padding: 5px 20px !important;
  width: 30% !important;
  border-bottom: solid 1px #ccc;
}
/* .mdl-tbl-dnt > thead tr {
  display: block;
  position: relative;

  
}
.mdl-tbl-dnt > thead tr th{
  padding-top: 22px;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.mdl-tbl-dnt td:nth-child(1), 
.mdl-tbl-dnt th:nth-child(1){
    min-width: 200px;
}
.mdl-tbl-dnt td:nth-child(2),
.mdl-tbl-dnt th:nth-child(2) {
    min-width: 200px;
}
.mdl-tbl-dnt td:nth-child(3),
.mdl-tbl-dnt th:nth-child(3) {
    min-width: 200px;
}
.mdl-tbl-dnt td:nth-child(4),
.mdl-tbl-dnt th:nth-child(4) {
    min-width: 200px;
}

.mdl-tbl-dnt th,.mdl-tbl td {
    padding: 5px;
    text-align: left;
} */

.mdl-tbl-dnt > tbody  {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 430px;
  width: 100%;
  position: absolute;
}
/* .mdl-tbl-dnt > tbody tr:nth-child(2n) {
    /* background-color: #dddddd; */
    /* border-bottom: solid 1px #ccc;
    border-top: solid 1px #ccc;
} 
 */

.violated-apps-table {
  width: 100% !important;
  height: 500px !important;
  margin-left: auto;
  margin-right: auto;
  display: -moz-groupbox;
  position: relative
} 

/* .violated-apps-table > tbody {
  width: 500px !important;
}
.mdl-tbl > tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 390px;
  width: 100%;
  position: absolute;
}
.mdl-tbl > tbody::-webkit-scrollbar {
  width: 8px;
}
.mdl-tbl > tbody::-webkit-scrollbar-thumb {
  background: rgb(218, 218, 218);
  border-radius: 5px;
}

.mdl-tbl > tbody::-webkit-scrollbar-thumb:hover {
  background: rgb(213, 213, 213);
}
.mdl-tbl tr {
  width: 100%;
  display: inline-table;
  height: 60px;
}

.mdl-tbl td,
.mdl-tbl th {
  width: 45% !important;
  padding: 10px; 
	border: 1px solid #ccc; 
	text-align: left; 
	font-size: 13px;
}
*/
/* .mdl-tbl th{

  background: #3498db;
    color: white;
    font-weight: bold;
}  */
.pre-info {
  font-size: 1rem;
  color: #111;
}

canvas {
  cursor: pointer;
}

.app-time-heading {
  margin: 40px 0 !important;
  text-transform: uppercase;
  font-size: 1.5px;
  /* font-weight: bold; */
}

.tbl > thead{
  background-color: #3498db;
  color: #ffffff;
}
.tbl{
  width: 60% !important;
  height: 520px;
  display: -moz-groupbox;
  position: relative
  /* height: 450px !important; */
}

.tbl > tbody{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 385px;
  width: 100%;
  position: absolute;
}

.tbl > thead tr th{
  padding: 20px ;
  width:50%;
  font-size: 1rem;
  text-transform: uppercase;
}
.tbl > thead tr th:nth-child(2){
  padding-left: 15px !important;
}
.tbl > tbody tr td{
  display: inline-block;
  padding: 10px 20px ;
  width: 50%;
  /* min-width: 70%; */
  border-bottom: solid 1px #ccc;
}
.tbl > tbody tr{
  display: block;
}
/* .tbl > tbody tr td:nth-child(1){
  float: left;
} 
.tbl > tbody tr td:nth-child(2){
  float: right; */
/* }  */