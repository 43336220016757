/* Customize the style of the context menu */
.react-contextmenu {
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px 0;
  min-width: 150px;
  font-size: 14px;
  z-index: 1;
}

/* Customize the style of context menu items */
.react-contextmenu-item {
  padding: 8px 15px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.react-contextmenu-item:hover {
  background-color: #f0f0f0;
}


/* Dynamically set the position of the context menu based on the provided coordinates */
.react-contextmenu.react-contextmenu--visible {
  position: fixed;
  top: var(--contextmenu-y);
  left: var(--contextmenu-x);
}
