/*-----------------------------------------
------------- ( team_con )
-----------------------------------------*/
.team_con {
  background: #f9f9fb;
  padding: 15px;
}
.search {
  padding: 15px;
}
.search input {
  border: none;
  width: 300px;
  border-radius: 5px;
  padding: 8px 15px;
  padding-left: 40px;
  background: url(../../assets/images/search.svg) #f2f2f8 no-repeat;
  background-position: center left 10px;
}

.team_con a {
  display: inline;
}
.team_con ul {
  flex-wrap: wrap;
  display: flex;
}

.team_con ul li {
  list-style-type: none;
  padding: 15px;
  flex: 0 0 33.3333%;
}
.team_con .box {
  border-radius: 10px;
  position: relative;
  padding: 15px 20px;
}
.team_con .box .line {
  margin: 60px 0 20px;
  background: #000;
  height: 1px;
  opacity: 0.1;
}
.team_con .box h4 {
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.team_con .img_usr {
  display: flex;
  align-items: center;
}

.team_con .vcm {
  background: rgb(0 0 0 / 0.1);
  position: absolute;
  border-radius: 5px;
  padding: 2px 3px;
  bottom: 25px;
  right: 20px;
}
.team_con .vcm span {
  padding: 0 10px;
}
.team_con .vcm span:nth-child(1),
.team_con .vcm span:hover {
  border-radius: 4px;
  background: #fff;
}

.team_con .box.add {
  border: 2px dashed #c4c4c4;
  background: #fff;
  height: 192px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
.team_con .box.add .center img {
  width: 50px;
  margin-bottom: 15px;
}

@media (max-width: 1024px) {
  .team_con ul li {
    flex: 0 0 50%;
  }
}

@media (max-width: 768px) {
  .search input {
    width: 250px;
  }
  .team_con ul li {
    flex: 0 0 100%;
  }
  .team_con .vcm a {
    padding: 0 6px;
  }
  .team_con .box .line {
    margin: 40px 0 20px;
  }
  .team_con {
    padding: 10px 0 0;
  }
  .search {
    padding-bottom: 0;
  }
}
@media (max-width: 575px) {
  .search input {
    width: 100%;
  }
}
.card-header {
  background-color: #4ea3db !important;
}
.white-color-text {
  color: white;
  font-weight: bold;
}
.accordion {
  border: 20px solid #233963 !important;
}
.bold1 {
  font-weight: bold;
  display: inline;
}
.card-div {
  width: 30%;
  background-color: #4ea3db;
  color: white;
  border: 5px solid #233963;
  margin: 10px;
}
.flex-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
