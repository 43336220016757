.bar-chart-container{
    width: 80%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.app-time-heading{
    font-size: 1.2rem;
    color: #111;
    text-align: center;
    margin-bottom: 10px;
}
.app-time-container{
    margin-top: 20px;
}

.post-text{
    text-align: right;
    font-size: 0.8rem;
    padding-top: 20px;
    
}

.activity-filters {
    display: flex;
    justify-content: space-around;
    margin-top: 32px;
}