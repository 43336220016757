.roomname-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
input {
  margin-bottom: 0px !important;
}

.input-container {
  margin-bottom: 5px !important;
}
.submit-btn {
  display: flex;
  justify-content: center;
}
.error-msg {
  color: red;
}

.img_background {
  cursor: pointer;
}

.back_image_li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete_back_div {
  background-color: #26a3db;
  width: 40px;
  display: flex;
  justify-content: center;
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
}

.delete_back_icon {
  font-size: 1.3rem;
  color: white;
}

.delete_back_div:hover {
  background-color: #007bff;
}
