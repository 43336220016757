.acc2 {
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  display: block;
}
.white-font {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
}
/* .gantt-chart-container > div {
  height: 150px;
} */
.chart-container {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0;
}
.acc-9 {
  display: flex;
  background-color: #d9dede;
  color: black;
  padding: 20px;
  padding-right: 5px;
  border: 1px solid black;
  margin-top: 15px;
}
.acc-9 p {
  font-weight: bold;
}
.acc-9p {
  width: 95%;
  text-align: center;
  font-size: 12px;
}
.acc-9f {
  width: 5%;
}
