.margin-left-10 {
  margin-left: 20px;
}

.search-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 21%;
}

.radio-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radio-container>div {
  display: flex;
  font-size: 0.8rem;
  font-weight: 500;
  color: #111;
}





body {
  font-family: Arial, sans-serif;
}

.permission-table {
  border-collapse: collapse;
  width: 100%;
}

.table-data {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

input[type="radio"] {
  margin-right: 5px;
}

input[disabled]+label {
  color: #aaa;
}

.count-span{
  font-size: 1rem;
  font-family: 'Courier New', Courier, monospace;
  color: #4c4c4c;

}

.t-head{
  background-color:   #26a3db;
  color: #fff;
  border: none;
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}
.ws-err{
  color: red;
}