/*-----------------------------------------
------------- ( login-page )
-----------------------------------------*/
.login-page {
  background: #e9f6fb;
  position: relative;
  padding: 50px;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.login-page:after,
.login-page:before {
  content: "";
  height: 200px;
  width: 200px;
  position: absolute;
  background: #d4edf8;
}
.login-page:after {
  top: 0;
  right: 0;
  border-bottom-left-radius: 300px;
}
.login-page:before {
  left: 0;
  bottom: 0;
  border-top-right-radius: 300px;
}

.login-page .bg-box {
  position: relative;
  z-index: 1;
}
.login-page .bg-box {
  background: url(../../assets/images/banner.svg) center;
  background-size: cover;
  height: 100%;
  width: 70%;
}
.login-page .form {
  z-index: 1;
  width: 30%;
  height: 100%;
  background: #fff;
  padding: 15px 30px;
  position: relative;
}
.login-page .form img {
  display: block;
  max-width: 150px;
  margin: 0 auto 5px;
}
.login-page .form h4 {
  font-weight: 800;
}
.login-page .form h6 {
  margin-bottom: 0;
  font-weight: 600;
}
.login-page .form button {
  width: 100%;
  margin-bottom: 10px;
}
.login-page .form p a {
  margin-bottom: 0;
}
.login-page .form a {
  color: #1e3869;
}

.logout {
  cursor: pointer;
}

.btn-link-a {
  color: #1e3869;
  cursor: pointer;
  font-size: 12px;
  line-height: 22px;
  display: inline;
  background-color: transparent;
  text-align: left;
  border: none;
  padding: 2px;
}
.btn-link-a:focus {
  outline: none;
}
@media (max-width: 1025px) {
  .login-page .bg-box {
    width: 65%;
  }
  .login-page .form {
    width: 35%;
  }
}
@media (max-width: 768px) {
  .login-page {
    height: auto;
    padding: 30px;
    flex-direction: column-reverse;
  }
  .login-page .bg-box {
    width: 100%;
    height: 250px;
  }
  .login-page .form {
    width: 100%;
  }
}

.link {
  margin-top: 10px;
}
