.legend rect {
  fill: white;
  stroke: black;
  opacity: 0.618;
}
.search {
  padding: 2%;
  background-color: #f8f9fa;
  float: left;
  width: 17%;
}

.right {
  width: 83%;
  float: right;
  overflow-y: auto;
}

.card {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 1%;
}

.card:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header {
  text-align: center;
}
.header h1 {
  margin-bottom: 0px;
}

.mgin {
  margin-top: 1%;
}

#filterSec {
  display: none;
}
.hw {
  width: 100%;
  height: 100%;
}
.pieslice-angry {
  fill: red !important;
}
.pieslice-focussed {
  fill: red !important;
}
.pieslice-engaged {
  fill: green !important;
}
.pieslice-neutral {
  fill: green !important;
}
.pieslice-disengaged {
  fill: grey !important;
}
.pieslice-happy {
  fill: lawngreen !important;
}
.pieslice-sad {
  fill: orange !important;
}
.pieslice-thoughtful {
  fill: orange !important;
}
.pieslice-fearful {
  fill: orangered !important;
}
.pieslice-disgusted {
  fill: rgba(247, 0, 255, 0.5) !important;
}
.pieslice-suprised {
  fill: blue !important;
}
.disp-none {
  display: none;
}
#idSessions {
  /* width: 96vw; */
  padding: 10px;
}
.datepicker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.datepicker-space {
  margin: 0px 10px;
  width: 35% !important;
}
.select-item > button#menu-dropdown-name,
.select-item > button#menu-dropdown-room {
  background: white;
  color: black;
  border: 1px solid #ced4da;
  border-radius: 50px;
}

.filters-container {
  display: flex;
  float: right;
  width: 44%;
  /* justify-content: space-around; */
}
.pie-chart-container {
  margin-top: 110px;
  display: flex;
  justify-content: center;
}
.report-container {
  padding: 2%;
  margin: 1%;
  width: 100%;
  border: 2px solid #f2f1f4;
  border-radius: 20px;
}
.no-chart-div {
  display: flex;
  justify-content: center;
  background: #89969ec4;
  color: white;
  padding: 40px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
  margin: 0;
  width: 93vw;
  margin-top: 30px;
}
.mkg {
  color: rgb(35 56 105);
  display: flex;
}
.session-chart {
  font-size: 20px;
  font-weight: bold;
}
.marg-10 {
  margin-left: 10px;
}
.ullist {
  display: flex;
  flex-direction: column;
}

.flex-dispo {
  width: 16%;
  display: flex;
}
.select-item {
  width: 15vw;
  margin: 0px 10px;
}
.form-check-input {
  width: 30vw;
}
.custom-select {
  width: 15vw !important;
}
.marg-100 {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.filteritemparent {
  display: flex;
  justify-content: space-around;
  width: 70vw;
}
.disp-flex-left {
  display: flex;
  flex-direction: row-reverse;
}

.hide {
  display: none !important;
}
.hide1 {
  display: none !important;
}

.pagination {
  justify-content: center;
}
#enablefilter {
  margin-left: 10px;
}
.form-k {
  margin-left: 25px;
}

.export_button {
  width: 20% !important;
}

.message_export_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.export_spinner {
  margin: 20px;
  width: 5rem !important;
  height: 5rem !important;
  font-size: 1.5rem !important;
}

.exp_mod_class {
  width: 100%;
  flex-wrap: wrap;
}

.exp_mod_class > .link {
  width: 33%;
}

.analytics {
  cursor: pointer;
}

.analytics:hover {
  color: #17a2b8;
}

/* .modal-content{
    width: 1200px !important;
    max-width: 2200px !important    ;
    height: 60vh !important;
    max-height: 60vh !important;

} */

.modal::-webkit-scrollbar {
  width: 0 !important;
}
.modal-header-container {
  display: flex;
  width: 150%;
  justify-content: space-between;
  align-items: center;
}
.modal-header-container > p {
  font-size: 1rem;
  font-weight: bold;
  color: #111;
}

.modal-header-container > p span {
  font-weight: normal;
  display: block;
}
.modal-height-dialog {
  height: 95%;
  min-width: 90%;
}

.modal-height {
  height: 95%;
  min-width: 90%;
  overflow-y: scroll;
}

.modal-height::-webkit-scrollbar {
  width: 10px;
}
.modal-height::-webkit-scrollbar-thumb {
  background-color: rgb(190, 190, 190);
  border-radius: 5px;
}

.modal-height::-webkit-scrollbar-track {
  background: #f7f7f7;
}

.view-details-btn {
  width: 5.8rem;
  height: 1.8rem;
  font-size: 0.7rem;
}
.activity-thead {
  background-color: #3498db;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
}

.stats-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.stats-container > div {
  width: 300px;
  height: 150px;
  border: solid 2px #fff;
  border-radius: 10px;
  background: #d3ff99;
  -webkit-box-shadow: 10px 10px 25px 0px rgba(232, 232, 232, 1);
  -moz-box-shadow: 10px 10px 25px 0px rgba(232, 232, 232, 1);
  box-shadow: 10px 10px 25px 0px rgba(232, 232, 232, 1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  padding-left: 10px;
}
.stats-container > div h1 {
  font-size: 1.3rem;
  margin-bottom: 0;
  color: black;
}

.stats-container > div p {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0;
  color: rgb(0, 0, 24);
}
.stats-container > div:nth-child(2) {
    background: #a7ecee;
  
}
.stats-container > div:nth-child(3) {
    background: #ff5858; 
    
}
.stats-container > div:nth-child(3) h1,.stats-container > div:nth-child(3) p{
  color: #fff;
}


.stats-container > div:nth-child(4) {
    background-color: #c0dbea;
  
}
.stats-container > div:nth-child(1) {
    background: #99dbf5;
}

.collps-btn {
  width: 300px;
}

.webrtc-btn {
  width: 800px;
  padding: 0;
  height: 56px;
}

.webrtc-filter {
  width: 70% !important;
}

.webrtc-filter-btn {
  width: 80%;
}

/* 99DBF5 */

/* A7ECEE */

/* FEA1A1 */
/* C0DBEA */

.link:hover{
  color: white;
  background: #3498db !important;
}