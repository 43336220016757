.search-user_view {
  display: flex;
  justify-content: space-between;
  align-items: right;
  width: 40%;
}
.add_btn_group {
  width: 225px;
  margin-right: 10px;
}
