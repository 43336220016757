.margin-left-10 {
  margin-left: 20px;
}

.search-user_view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 21% !important;
}
.export_btn_users {
  width: 225px;
  margin-right: 10px;
}
.gen-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  font-size: 0.9rem;
  font-family: sans-serif;
}
