@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	color: #747474;
	font-size: 12px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: .5px;
	font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
	margin: 0;
	font-weight: 400;
	margin-bottom: 8px;
	word-wrap: break-word;
	font-family: 'Muli', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #1E3869;
}

h1 {
	font-size: 42px;
	line-height: 52px;
}

h2 {
	font-size: 32px;
	line-height: 42px;
}

h3 {
	font-size: 28px;
	font-weight: 800;
	line-height: 38px;
}

h4 {
	font-size: 22px;
	line-height: 32px;
}

h5 {
	font-size: 18px;
	line-height: 28px;
}

h6 {
	font-size: 14px;
	line-height: 24px;
}

p {
	font-size: 12px;
	line-height: 20px;
}

a {
	cursor: pointer;
	font-size: 12px;
	line-height: 22px;
	display: inline-block;
}

span {
	display: inline-block;
}

img {
	width: 100%;
	-webkit-transition: 0.3s ease-in-out;
	-moz-transition: 0.3s ease-in-out;
	-ms-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}

ul {
	margin: 0;
	padding: 0;
}

a:hover {
	text-decoration: none;
}

div {
	margin: 0;
	padding: 0;
}

section {
	z-index: 1;
	padding: 50px 0;
	position: relative;
}

input,textarea,select{
	border: 1px solid #26A3DB;
	border-radius: 5px;
	padding: 6px 7px;
	letter-spacing: .5px;
	font-size: 14px;
	width: 100%;
}
input:focus,textarea:focus{
	outline: none;
}


.di{
	display: inline-block;
}

/*-- Button, Anchor --*/

.link {
	color: #fff;
	border: none;
	font-size: 12px;
	padding: 8px 15px;
	border-radius: 5px;
	background: #26A3DB;
	letter-spacing: 1px;
}

.link:hover {
	background: #1E3869;
}

