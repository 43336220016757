.all_users {
  display: flex;
  justify-content: center;
}

.all_users .report-container {
  display: flex;
  justify-content: space-around;
  width: 90rem;
  padding: 8px;
  margin: 0px;
}

.single_user_index,
.single_user_name {
  width: 50%;
  display: flex;
  justify-content: center;
}
