.loader{
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: .5;
    color: white;
    display:flex;
    justify-content: center;
    text-align: center;
    background: rgba(0,0,0,.5) url('../../assets/images/loader.gif') center center no-repeat;
  }