.marg-left-10 {
  margin-left: 10px;
}
.marg-up-10 {
  margin-top: 10px;
}
.red-alert {
  color: red;
}

.warning-alert {
  color: orange;
}


.prog20 {
  accent-color: red;
}

.prog40 {
  accent-color: red;
}

.prog60 {
  accent-color: orange;
}

.prog80 {
  accent-color: orange;
}

.prog100 {
  accent-color: green;
}

.toggle-switch {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.toggle-label {
  margin-right: 10px;
}
