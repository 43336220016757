.roomname-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
input {
  margin-bottom: 0px !important;
}

.input-container {
  margin-bottom: 5px !important;
}
.submit-btn {
  display: flex;
  justify-content: center;
  float:right;
}
.error-msg {
  color: red;
}
.toggle-label {
  background-color:  #18bfae33;
  color: #141313d1;
  border-radius: 25px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  user-select: none;
}
.toggle-enabled {
  background-color: #31bf1833;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
