/* Style for the application names */
.app-name {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  .app_container {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .app-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Style for the table header */
  .table-header {
    background-color: #5fa2e0;
    color: #fff;
    padding: 10px;
    text-align: center;
    font-size: 16px;
  }
  
  /* Style for the table rows */
  .table-row:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Style for the table cells */
  .table-row td:nth-child(n + 2) {
    text-align: center;
  }
  
  /* Hide default radio buttons */
  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
  }
  
  /* Create custom radio button style */
  input[type="radio"] {
    width: 20px;
    height: 20px;
    border: 2px solid #5fa2e0;
    /* Border color for unchecked radio buttons (skyblue) */
    margin: 0;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    /* Add pointer cursor style */
    transition: background-color 0.3s ease, border-color 0.3s ease;
    /* Add transition for animation */
  }
  
  /* Customize radio button appearance when checked */
  input[type="radio"]:checked {
    background-color: #5fa2e0;
    /* Background color for checked radio buttons (skyblue) */
    border-color: #5fa2e0;
    /* Border color for checked radio buttons (skyblue) */
  }
  
  /* Add inner circle (tick) for checked state */
  input[type="radio"]:checked::before {
    content: "\2714";
    /* Unicode character for checkmark (tick) */
    width: 14px;
    height: 14px;
    color: #fff;
    /* Color of the tick mark (white) */
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    /* Start with a scaled down tick mark for animation */
    opacity: 0;
    /* Start with opacity set to 0 for fade-in effect */
    transition: transform 0.2s ease, opacity 0.2s ease;
    /* Add transition for animation */
  }
  
  /* Animation to fade-in and scale up the tick mark when checked */
  input[type="radio"]:checked::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  /* WarningMessage.css */
  .warning-container {
    display: flex;
    /* Use flexbox to make the items side by side */
    align-items: center;
    /* Vertically center items */
    justify-content: space-between;
    /* Distribute items with space between them */
    width: fit-content;
    margin: 0 auto;
  }
  
  .warning-message {
    color: #333;
    padding: 10px;
  
    font-size: 16px;
  }
  
  .submit-button {
    padding: 8px 16px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .card {
    height: 400px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 28%;
  }
  
  .card:hover {
    transform: none !important;
  }
  
  .card-header {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: 500;
    color: #111;
    text-align: center;
    text-transform: uppercase !important;
    padding-bottom: 8px !important;
    border-bottom: solid 2px #26a3db;
    
    width: 100%;
  }
  .card-body {
    padding: 0 !important;
    width: 100%;
  
    overflow-y: scroll;
  }
  .card-body::-webkit-scrollbar {
    width: 8px;
    background-color: #f6f6f6;
  }
  
  .card-body::-webkit-scrollbar-thumb {
    background-color: rgb(210, 210, 210);
    border-radius: 5px;
  }
  .card-body::-webkit-scrollbar-thumb:hover {
    background-color: grey;
  }
  .list-group-item {
    padding-left: 10px !important;
    font-size: 0.9rem;
    cursor: pointer;
    color: rgb(97, 97, 97);
  }
  .list-group-item:hover {
    background: #f9f9f9;
    color: #000000;
    
  }
  
  
  @media (max-width:900px){
    .app_container{
      flex-direction: column;
      width: 100%;
    } 
  
    .card{
      width: 90%;
    }
  }
  
  