.marg-left-10 {
  margin-left: 10px;
}
.marg-up-10 {
  margin-top: 10px;
}
.red-alert {
  color: red;
}

.tabs-container {
  height: 60px;
  width: 70%;
}
.tabs-container > a {
  font-size: 1.2rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 1px 5px -1px rgba(184, 184, 184, 1);
  -moz-box-shadow: 0px 1px 5px -1px rgba(184, 184, 184, 1);
  box-shadow: 0px 1px 5px -1px rgba(184, 184, 184, 1);
  color: #111;
  
}
.tabs-container > a:hover {
  color: #111;
}
.active {
  background: #009dd1 !important;
  /* background: linear-gradient(180deg, rgba(0,205,237,1) 0%, rgba(141,239,255,1) 40%, rgba(0,220,255,1) 72%) !important; */

  color: #fff !important;
}

.tab-content .active {
  background: transparent !important;
  color: #111 !important;
}
.tab-content {
  margin: 0 !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-content-container {
  width: 90%; 
  border-radius: 5px;
  text-align: left;
  -webkit-box-shadow: 0px 1px 6px -1px rgba(184, 184, 184, 1);
  -moz-box-shadow: 0px 1px 6px -1px rgba(184, 184, 184, 1);
  box-shadow: 0px 1px 6px -1px rgba(184, 184, 184, 1);
  display: flex;
  justify-content: center;
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
.tab-content-container > form {
  width: 95%;
}

.form-check-input {
  width: 1vw !important;
}

.admin_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}