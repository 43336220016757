
h1 {
    margin: 0;
    line-height: 2;
    text-align: center;
}
h2 {
    margin: 0 0 0.5em;
    font-weight: normal;
}
.stats-input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
.row {
    display: flex;
}
.row .col {
    flex: 1;
}
.row .col:last-child {
    margin-left: 1em;
    margin-right: 1em;
}

.tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}
.tab {
    width: 100%;
    color: white;
    overflow: hidden;
}
.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #2c3e50;
    font-weight: bold;
    cursor: pointer;
}
.tab-label:hover {
    background: #1a252f;
}
.tab-label::after {
    content: "\276F";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
}
.tab-content-stats {
    max-height: 0;
    padding: 0 1em;
    color: gray;
    background: white;
    transition: all 0.35s;
    font-size: 15px;
    font-weight: bold;
}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
}
.tab-close:hover {
    background: #1a252f;
}

input:checked + .tab-label {
    background: #1a252f;
}
input:checked + .tab-label::after {
    transform: rotate(90deg);
}
input:checked ~ .tab-content-stats {
    max-height: 100vh;
    padding: 1em;
}
.stats-error-red{
    color:brown;
}
.stats-error-green {
    color:darkgreen;
}
.stats-error-dark {
    color:sienna;
}
.stats-error-date {
    color: orange;
}

