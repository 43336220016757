.box {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 18px;
}
.images-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}

.comment {
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-feed {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 22%;
    margin-left: 30px;
    margin-top: 18px;
    display:inline-block;
}
.feedback-card{
    width: 85% !important;
}
.card img {
    cursor: pointer;
}

/*.card img {*/
/*    width: 15%;*/
/*}*/
/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
    padding: 2px 16px;
}