@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/*-----------------------------------------
------------- ( team_con )
-----------------------------------------*/
.team_con {
  background: #f9f9fb;
  padding: 15px;
}
.search {
  padding: 15px;
}
.search input {
  border: none;
  width: 300px;
  border-radius: 5px;
  padding: 8px 15px;
  padding-left: 40px;
  background: url(/static/media/search.dce1b61e.svg) #f2f2f8 no-repeat;
  background-position: center left 10px;
}

.team_con a {
  display: inline;
}
.team_con ul {
  flex-wrap: wrap;
  display: flex;
}

.team_con ul li {
  list-style-type: none;
  padding: 15px;
  flex: 0 0 33.3333%;
}
.team_con .box {
  border-radius: 10px;
  position: relative;
  padding: 15px 20px;
}
.team_con .box .line {
  margin: 60px 0 20px;
  background: #000;
  height: 1px;
  opacity: 0.1;
}
.team_con .box h4 {
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.team_con .img_usr {
  display: flex;
  align-items: center;
}

.team_con .vcm {
  background: rgb(0 0 0 / 0.1);
  position: absolute;
  border-radius: 5px;
  padding: 2px 3px;
  bottom: 25px;
  right: 20px;
}
.team_con .vcm span {
  padding: 0 10px;
}
.team_con .vcm span:nth-child(1),
.team_con .vcm span:hover {
  border-radius: 4px;
  background: #fff;
}

.team_con .box.add {
  border: 2px dashed #c4c4c4;
  background: #fff;
  height: 192px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
.team_con .box.add .center img {
  width: 50px;
  margin-bottom: 15px;
}

@media (max-width: 1024px) {
  .team_con ul li {
    flex: 0 0 50%;
  }
}

@media (max-width: 768px) {
  .search input {
    width: 250px;
  }
  .team_con ul li {
    flex: 0 0 100%;
  }
  .team_con .vcm a {
    padding: 0 6px;
  }
  .team_con .box .line {
    margin: 40px 0 20px;
  }
  .team_con {
    padding: 10px 0 0;
  }
  .search {
    padding-bottom: 0;
  }
}
@media (max-width: 575px) {
  .search input {
    width: 100%;
  }
}
.card-header {
  background-color: #4ea3db !important;
}
.white-color-text {
  color: white;
  font-weight: bold;
}
.accordion {
  border: 20px solid #233963 !important;
}
.bold1 {
  font-weight: bold;
  display: inline;
}
.card-div {
  width: 30%;
  background-color: #4ea3db;
  color: white;
  border: 5px solid #233963;
  margin: 10px;
}
.flex-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


#myHeader {
  top: 0;
  width: 99.99%;
  z-index: 99;
  background: #fff;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-name: hdrfix;
  animation-name: hdrfix;
  box-shadow: 0 0 5px #aaaaaa;
  position: relative;
}

@-webkit-keyframes hdrfix {
  from {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.icon-img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.flex {
  display: flex;
}

.mr-4 {
  margin-right: 4px;
}

@keyframes hdrfix {
  from {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

/*-----------------------------------------
  ------------- ( header )
  -----------------------------------------*/
header {
  border-bottom: 1px solid #e2e1e7;
}
header ul {
  display: flex;
  align-items: center;
}
header .left-box ul li {
  flex: 0 0 50%;
  padding: 12px 0;
  text-transform: uppercase;
}
header .left-box .logo {
  padding-right: 15px;
  border-right: 1px solid #e2e1e7;
}
header .left-box h4 {
  margin-bottom: 0;
  padding-left: 15px;
  font-weight: 600;
}
header .center-box a {
  display: flex;
  align-items: center;
}
header .center-box ul {
  justify-content: center;
}
header .center-box ul li a {
  margin: 0 5px;
}
header select {
  color: #747474;
  padding: 6px 12px;
  background: #f9f9fb;
  border-radius: 5px;
}
header .center-box a img {
  width: auto;
  height: 20px;
  margin-right: 7px;
}

header .right-box ul {
  padding: 7px 0;
  justify-content: flex-end;
  border-left: 1px solid #e2e1e7;
}
header .right-box ul li {
  padding: 0 10px;
}
header .right-box .profile {
  display: flex;
  align-items: center;
}
header .right-box .profile img {
  border-radius: 5px;
  max-width: 38px;
  margin-right: 8px;
}

header .right-box .dropdown-toggle {
  background: #26a3db;
  border: none;
  color: #fff;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 5px;
}
header .right-box .dropdown-toggle:hover,
header .right-box .dropdown-toggle[aria-expanded="true"] {
  background: #1e3869;
  outline: none !important;
}
header .right-box .dropdown-menu {
  padding: 5px 10px;
}
header .right-box .dropdown-menu a {
  width: 100%;
}

header .right-box .logout:hover {
  opacity: 0.5;
}

@media (max-width: 1024px) {
  header .left-box h4 {
    font-size: 18px;
    line-height: 32px;
    padding-left: 10px;
  }
  header .left-box .logo {
    padding-right: 10px;
  }
  header .center-box ul li a {
    margin: 0 2px;
  }
  header select {
    padding: 3px 4px;
  }
  header .center-box a img {
    height: 16px;
    margin-right: 3px;
  }
  header .right-box ul li {
    padding: 0 5px;
  }
  header .right-box .dropdown-toggle {
    letter-spacing: 0.5px;
    font-size: 13px;
    padding: 5px 8px;
  }
  header .right-box .profile img {
    margin-right: 5px;
  }
}

@media (max-width: 991px) {
  header .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .center-box {
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    padding: 20px 0;
  }
  header .left-box h4 {
    font-size: 22px;
    line-height: 32px;
    padding-left: 0;
    text-align: center;
  }
  header .left-box .logo {
    text-align: center;
    padding-right: 0;
  }
  header .left-box .logo img {
    max-width: 230px;
  }
  header .center-box a img {
    height: 22px;
    margin-right: 5px;
  }
  header .center-box ul li a {
    margin: 0 10px;
  }
  header select {
    padding: 3px 10px;
  }
  header .right-box ul {
    padding: 12px 0;
    border-left: none;
    justify-content: center;
  }
  #myHeader[style="position: fixed;"] .col-md-4:nth-child(1),
  #myHeader[style="position: fixed;"] .col-md-4:nth-child(2) {
    display: none;
  }
}

@media (max-width: 768px) {
  header .left-box h4 {
    font-size: 18px;
    line-height: 26px;
  }

  header .left-box .logo img {
    max-width: 160px;
  }
  header .right-box .profile img {
    margin-right: 10px;
  }
  header .center-box ul li a {
    margin: 0 5px;
  }
  header .right-box .dropdown-toggle {
    padding: 5px 12px;
  }
}
li {
  list-style: none;
}

.loader{
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: .5;
    color: white;
    display:flex;
    justify-content: center;
    text-align: center;
    background: rgba(0,0,0,.5) url(/static/media/loader.26d86ed1.gif) center center no-repeat;
  }
.marg-left-10 {
  margin-left: 10px;
}
.marg-up-10 {
  margin-top: 10px;
}
.red-alert {
  color: red;
}

.warning-alert {
  color: orange;
}

.prog20 {
  accent-color: red;
}

.prog40 {
  accent-color: red;
}

.prog60 {
  accent-color: orange;
}

.prog80 {
  accent-color: orange;
}

.prog100 {
  accent-color: green;
}

.toggle-switch {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.toggle-label {
  margin-right: 10px;
}

input{
	margin-bottom: 15px !important;
}
.search_dropdown {
  position: relative;
  color: #333;
  cursor: auto;

}

.search_dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.search_dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.search_dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.search_dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.search_dropdown .options.open {
  display: block;
}

.search_dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.search_dropdown .option.selected,
.search_dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.marg-left-10 {
  margin-left: 10px;
}
.marg-up-10 {
  margin-top: 10px;
}
.red-alert {
  color: red;
}

.warning-alert {
  color: orange;
}


.prog20 {
  accent-color: red;
}

.prog40 {
  accent-color: red;
}

.prog60 {
  accent-color: orange;
}

.prog80 {
  accent-color: orange;
}

.prog100 {
  accent-color: green;
}

.toggle-switch {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.toggle-label {
  margin-right: 10px;
}

.margin-left-10 {
  margin-left: 20px;
}

.search-user_view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 21% !important;
}
.export_btn_users {
  width: 225px;
  margin-right: 10px;
}
.gen-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  font-size: 0.9rem;
  font-family: sans-serif;
}

.marg-left-10 {
  margin-left: 10px;
}
.marg-up-10 {
  margin-top: 10px;
}
.red-alert {
  color: red;
}

.marg-left-10 {
  margin-left: 10px;
}
.marg-up-10 {
  margin-top: 10px;
}
.red-alert {
  color: red;
}

.tabs-container {
  height: 60px;
  width: 70%;
}
.tabs-container > a {
  font-size: 1.2rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px -1px rgba(184, 184, 184, 1);
  color: #111;
  
}
.tabs-container > a:hover {
  color: #111;
}
.active {
  background: #009dd1 !important;
  /* background: linear-gradient(180deg, rgba(0,205,237,1) 0%, rgba(141,239,255,1) 40%, rgba(0,220,255,1) 72%) !important; */

  color: #fff !important;
}

.tab-content .active {
  background: transparent !important;
  color: #111 !important;
}
.tab-content {
  margin: 0 !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-content-container {
  width: 90%; 
  border-radius: 5px;
  text-align: left;
  box-shadow: 0px 1px 6px -1px rgba(184, 184, 184, 1);
  display: flex;
  justify-content: center;
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
.tab-content-container > form {
  width: 95%;
}

.form-check-input {
  width: 1vw !important;
}

.admin_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.marg-left-10 {
  margin-left: 10px;
}
.marg-up-10 {
  margin-top: 10px;
}
.red-alert {
  color: red;
}

.legend rect {
  fill: white;
  stroke: black;
  opacity: 0.618;
}
.search {
  padding: 2%;
  background-color: #f8f9fa;
  float: left;
  width: 17%;
}

.right {
  width: 83%;
  float: right;
  overflow-y: auto;
}

.card {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 1%;
}

.card:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.header {
  text-align: center;
}
.header h1 {
  margin-bottom: 0px;
}

.mgin {
  margin-top: 1%;
}

#filterSec {
  display: none;
}
.hw {
  width: 100%;
  height: 100%;
}
.pieslice-angry {
  fill: red !important;
}
.pieslice-focussed {
  fill: red !important;
}
.pieslice-engaged {
  fill: green !important;
}
.pieslice-neutral {
  fill: green !important;
}
.pieslice-disengaged {
  fill: grey !important;
}
.pieslice-happy {
  fill: lawngreen !important;
}
.pieslice-sad {
  fill: orange !important;
}
.pieslice-thoughtful {
  fill: orange !important;
}
.pieslice-fearful {
  fill: orangered !important;
}
.pieslice-disgusted {
  fill: rgba(247, 0, 255, 0.5) !important;
}
.pieslice-suprised {
  fill: blue !important;
}
.disp-none {
  display: none;
}
#idSessions {
  /* width: 96vw; */
  padding: 10px;
}
.datepicker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.datepicker-space {
  margin: 0px 10px;
  width: 35% !important;
}
.select-item > button#menu-dropdown-name,
.select-item > button#menu-dropdown-room {
  background: white;
  color: black;
  border: 1px solid #ced4da;
  border-radius: 50px;
}

.filters-container {
  display: flex;
  float: right;
  /* justify-content: space-around; */
}
.pie-chart-container {
  margin-top: 110px;
  display: flex;
  justify-content: center;
}
.report-container {
  padding: 2%;
  margin: 1%;
  width: 100%;
  border: 2px solid #f2f1f4;
  border-radius: 20px;
}
.no-chart-div {
  display: flex;
  justify-content: center;
  background: #89969ec4;
  color: white;
  padding: 40px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
}
.mkg {
  color: rgb(35 56 105);
  display: flex;
}
.session-chart {
  font-size: 20px;
  font-weight: bold;
}
.marg-10 {
  margin-left: 10px;
}
.ullist {
  display: flex;
  flex-direction: column;
}

.flex-dispo {
  width: 16%;
  display: flex;
}
.select-item {
  width: 15vw;
  margin: 0px 10px;
}
.form-check-input {
  width: 30vw;
}
.custom-select {
  width: 15vw !important;
}
.marg-100 {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.filteritemparent {
  display: flex;
  justify-content: space-around;
  width: 70vw;
}
.disp-flex-left {
  display: flex;
  flex-direction: row-reverse;
}

.hide {
  display: none !important;
}
.hide1 {
  display: none !important;
}

.pagination {
  justify-content: center;
}
#enablefilter {
  margin-left: 10px;
}
.form-k {
  margin-left: 25px;
}

.export_button {
  width: 20% !important;
}

.message_export_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.export_spinner {
  margin: 20px;
  width: 5rem !important;
  height: 5rem !important;
  font-size: 1.5rem !important;
}


.exp_mod_class{
  width: 100%;
  flex-wrap: wrap;
}

.exp_mod_class >.link{
  width:33%;
}
.acc2 {
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  display: block;
}
.white-font {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
}
/* .gantt-chart-container > div {
  height: 150px;
} */
.chart-container {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0;
}
.acc-9 {
  display: flex;
  background-color: #d9dede;
  color: black;
  padding: 20px;
  padding-right: 5px;
  border: 1px solid black;
  margin-top: 15px;
}
.acc-9 p {
  font-weight: bold;
}
.acc-9p {
  width: 95%;
  text-align: center;
  font-size: 12px;
}
.acc-9f {
  width: 5%;
}



/*-----------------------------------------
------------- ( login-page )
-----------------------------------------*/
.login-page {
  background: #e9f6fb;
  position: relative;
  padding: 50px;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.login-page:after,
.login-page:before {
  content: "";
  height: 200px;
  width: 200px;
  position: absolute;
  background: #d4edf8;
}
.login-page:after {
  top: 0;
  right: 0;
  border-bottom-left-radius: 300px;
}
.login-page:before {
  left: 0;
  bottom: 0;
  border-top-right-radius: 300px;
}

.login-page .bg-box {
  position: relative;
  z-index: 1;
}
.login-page .bg-box {
  background: url(/static/media/banner.4c1d72ef.svg) center;
  background-size: cover;
  height: 100%;
  width: 70%;
}
.login-page .form {
  z-index: 1;
  width: 30%;
  height: 100%;
  background: #fff;
  padding: 15px 30px;
  position: relative;
}
.login-page .form img {
  display: block;
  max-width: 150px;
  margin: 0 auto 5px;
}
.login-page .form h4 {
  font-weight: 800;
}
.login-page .form h6 {
  margin-bottom: 0;
  font-weight: 600;
}
.login-page .form button {
  width: 100%;
  margin-bottom: 10px;
}
.login-page .form p a {
  margin-bottom: 0;
}
.login-page .form a {
  color: #1e3869;
}

.logout {
  cursor: pointer;
}

.btn-link-a {
  color: #1e3869;
  cursor: pointer;
  font-size: 12px;
  line-height: 22px;
  display: inline;
  background-color: transparent;
  text-align: left;
  border: none;
  padding: 2px;
}
.btn-link-a:focus {
  outline: none;
}
@media (max-width: 1025px) {
  .login-page .bg-box {
    width: 65%;
  }
  .login-page .form {
    width: 35%;
  }
}
@media (max-width: 768px) {
  .login-page {
    height: auto;
    padding: 30px;
    flex-direction: column-reverse;
  }
  .login-page .bg-box {
    width: 100%;
    height: 250px;
  }
  .login-page .form {
    width: 100%;
  }
}

.link {
  margin-top: 10px;
}

/*-----------------------------------------
------------- ( team_con )
-----------------------------------------*/
.team_con {
  display: flex;
  flex-direction: column;
  background: #f9f9fb;
  padding: 15px;
}
.search {
  padding: 15px;
}
.search input {
  border: none;
  width: 300px;
  border-radius: 5px;
  padding: 8px 15px;
  padding-left: 40px;
  background: url(/static/media/search.dce1b61e.svg) #f2f2f8 no-repeat;
  background-position: center left 10px;
}

.team_con a {
  display: inline;
}
.team_con a:hover {
  text-decoration: none;
}
.team_con ul {
  flex-wrap: wrap;
  display: flex;
}

.team_con ul li {
  list-style-type: none;
  padding: 15px;
  flex: 0 0 33.3333%;
}
.team_con .box {
  border-radius: 10px;
  position: relative;
  padding: 15px 20px;
}
.team_con .box .line {
  margin: 20px 0 20px;
  background: #000;
  height: 1px;
  opacity: 0.1;
}
.team_con .box h4 {
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.team_con .img_usr {
  display: flex;
  align-items: center;
}

.team_con .vcm {
  background: rgb(0 0 0 / 0.1);
  position: absolute;
  border-radius: 5px;
  padding: 2px 3px;
  bottom: 25px;
  right: 20px;
}
.team_con .vcm span {
  padding: 0 10px;
}
.team_con .vcm span:nth-child(1),
.team_con .vcm span:hover {
  border-radius: 4px;
  background: #fff;
}

.team_con .box.add {
  border: 2px dashed #c4c4c4;
  background: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
.team_con .box.add .center img {
  width: 50px;
  margin-bottom: 15px;
}

@media (max-width: 1024px) {
  .team_con ul li {
    flex: 0 0 50%;
  }
}

@media (max-width: 768px) {
  .search input {
    width: 250px;
  }
  .team_con ul li {
    flex: 0 0 100%;
  }
  .team_con .vcm a {
    padding: 0 6px;
  }
  .team_con .box .line {
    margin: 40px 0 20px;
  }
  .team_con {
    padding: 10px 0 0;
  }
  .search {
    padding-bottom: 0;
  }
}
@media (max-width: 575px) {
  .search input {
    width: 100%;
  }
}

.dashboard-avatar {
  width: 35px;
  height: 35px;
  background: #26a3db;
  border-radius: 10px;
  color: white;
  margin-right: 10px;
  position: relative;
}

.pravatar {
  height: 35px;
  width: 35px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.centered {
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.text {
  display: flex;
  flex-wrap: wrap;
}

.text h6,
.text p {
  margin-bottom: 0;
  flex: 0 0 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}
.imgContainer {
  display: flex;
  justify-content: space-between;
}
.imgSize {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.del_img {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  justify-content: space-between;
}

.del_img .del_bor {
  background: #fff;
  border-radius: 5px;
  padding: 2px 9px;
  cursor: pointer;
}

.del_img .del_bor:hover {
  background: rgb(0 0 0 / 0.1);
}

.pravatar {
  height: 35px;
  width: 35px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.centered {
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.logo-cont{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  width: 100%;
  padding: 5px;
  border-radius:5px;
  border: solid 1px #26A3DB;
  position: relative;
  
}
.logo-btn{
  width: 100px;
  background:#26A3DB;
  height: 100%;
  position: absolute;
  top:0;
  left: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: center;
  color: #fff; 
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.logo-cont > span{
  margin-left: 100px;
}

.form-img{
  width: 50px;
  padding-left: 5px !important;
}

.input-container-subdiv{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.roomname_heading{
  font-size: 1rem !important;
  width: 100%;
}
.roomname-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
input {
  margin-bottom: 0px !important;
}

.input-container {
  margin-bottom: 5px !important;
}
.submit-btn {
  display: flex;
  justify-content: center;
  float:right;
}
.error-msg {
  color: red;
}
.toggle-label {
  background-color:  #18bfae33;
  color: #141313d1;
  border-radius: 25px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.toggle-enabled {
  background-color: #31bf1833;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.del_mod > .link {
  margin: 5px 20px !important;
}

.all_users {
  display: flex;
  justify-content: center;
}

.all_users .report-container {
  display: flex;
  justify-content: space-around;
  width: 50rem;
  padding: 8px;
  margin: 0px;
}

.single_user_index,
.single_user_name {
  width: 50%;
  display: flex;
  justify-content: center;
}

.all_users {
  display: flex;
  justify-content: center;
}

.all_users .report-container {
  display: flex;
  justify-content: space-around;
  width: 50rem;
  padding: 8px;
  margin: 0px;
}

.single_user_index,
.single_user_name {
  width: 50%;
  display: flex;
  justify-content: center;
}

.button_spinner {
  margin-right: 8px !important;
  margin-bottom: 2px !important;
}

.trash_buss {
  cursor: pointer !important;
}

.all_users {
  display: flex;
  justify-content: center;
}

.all_users .report-container {
  display: flex;
  justify-content: space-around;
  width: 90rem;
  padding: 8px;
  margin: 0px;
}

.single_user_index,
.single_user_name {
  width: 50%;
  display: flex;
  justify-content: center;
}


.roomname-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
input {
  margin-bottom: 0px !important;
}

.input-container {
  margin-bottom: 5px !important;
}
.submit-btn {
  display: flex;
  justify-content: center;
}
.error-msg {
  color: red;
}

.img_background {
  cursor: pointer;
}

.back_image_li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete_back_div {
  background-color: #26a3db;
  width: 40px;
  display: flex;
  justify-content: center;
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
}

.delete_back_icon {
  font-size: 1.3rem;
  color: white;
}

.delete_back_div:hover {
  background-color: #007bff;
}

.box {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 18px;
}
.images-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}

.comment {
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-feed {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 22%;
    margin-left: 30px;
    margin-top: 18px;
    display:inline-block;
}
.feedback-card{
    width: 85% !important;
}
.card img {
    cursor: pointer;
}

/*.card img {*/
/*    width: 15%;*/
/*}*/
/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
    padding: 2px 16px;
}
#idSessions {
  padding: 35px;
}

.Collapsible {
  background-color: rgb(148, 154, 156);
}
.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid lightGrey;
  border-top: 0;
  mix-blend-mode: overlay;
}
.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}
.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}
.Collapsible__trigger {
  display: block;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  border: 5px solid white;
  padding: 10px;
  background: rgb(136, 163, 173);
  color: white;
  font-size: 14px;
}
.Collapsible__trigger:after {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}
.Collapsible__trigger.is-open:after {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}
.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}
.CustomTriggerCSS--open {
  background-color: darkslateblue;
}
.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

.geo-tabs-container {
  height: 60px;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.geo-tabs-container > a {
  font-size: 1.2rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px -1px rgba(184, 184, 184, 1);
  color: #111;
}
.geo-tabs-container > a:hover {
  color: #111;
}

.active {
  background: #009dd1 !important;
  /* background: linear-gradient(180deg, rgba(0,205,237,1) 0%, rgba(141,239,255,1) 40%, rgba(0,220,255,1) 72%) !important; */

  color: #fff !important;
}
.tab-content{
  width: 98vw !important;

}

.tab-content .active {
  background: transparent !important;
  color: #111 !important;
  width: 100% !important;
}

.nav-item {
  width: 40% !important;
  height: 60px;
}

.text-align-center {
  text-align: center;
}


.legend rect {
  fill: white;
  stroke: black;
  opacity: 0.618;
}
.search {
  padding: 2%;
  background-color: #f8f9fa;
  float: left;
  width: 17%;
}

.right {
  width: 83%;
  float: right;
  overflow-y: auto;
}

.card {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 1%;
}

.card:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.header {
  text-align: center;
}
.header h1 {
  margin-bottom: 0px;
}

.mgin {
  margin-top: 1%;
}

#filterSec {
  display: none;
}
.hw {
  width: 100%;
  height: 100%;
}
.pieslice-angry {
  fill: red !important;
}
.pieslice-focussed {
  fill: red !important;
}
.pieslice-engaged {
  fill: green !important;
}
.pieslice-neutral {
  fill: green !important;
}
.pieslice-disengaged {
  fill: grey !important;
}
.pieslice-happy {
  fill: lawngreen !important;
}
.pieslice-sad {
  fill: orange !important;
}
.pieslice-thoughtful {
  fill: orange !important;
}
.pieslice-fearful {
  fill: orangered !important;
}
.pieslice-disgusted {
  fill: rgba(247, 0, 255, 0.5) !important;
}
.pieslice-suprised {
  fill: blue !important;
}
.disp-none {
  display: none;
}
#idSessions {
  /* width: 96vw; */
  padding: 10px;
}
.datepicker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.datepicker-space {
  margin: 0px 10px;
  width: 35% !important;
}
.select-item > button#menu-dropdown-name,
.select-item > button#menu-dropdown-room {
  background: white;
  color: black;
  border: 1px solid #ced4da;
  border-radius: 50px;
}

.filters-container {
  display: flex;
  float: right;
  /* justify-content: space-around; */
}
.pie-chart-container {
  margin-top: 110px;
  display: flex;
  justify-content: center;
}
.report-container {
  padding: 2%;
  margin: 1%;
  width: 100%;
  border: 2px solid #f2f1f4;
  border-radius: 20px;
}
.no-chart-div {
  display: flex;
  justify-content: center;
  background: #89969ec4;
  color: white;
  padding: 40px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
}
.mkg {
  color: rgb(35 56 105);
  display: flex;
}
.session-chart {
  font-size: 20px;
  font-weight: bold;
}
.marg-10 {
  margin-left: 10px;
}
.ullist {
  display: flex;
  flex-direction: column;
}

.flex-dispo {
  width: 16%;
  display: flex;
}
.select-item {
  width: 15vw;
  margin: 0px 10px;
}
.form-check-input {
  width: 30vw;
}
.custom-select {
  width: 15vw !important;
}
.marg-100 {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.filteritemparent {
  display: flex;
  justify-content: space-around;
  width: 70vw;
}
.disp-flex-left {
  display: flex;
  flex-direction: row-reverse;
}

.hide {
  display: none !important;
}
.hide1 {
  display: none !important;
}

.pagination {
  justify-content: center;
}
#enablefilter {
  margin-left: 10px;
}
.form-k {
  margin-left: 25px;
}

.export_button {
  width: 20% !important;
}

.message_export_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.export_spinner {
  margin: 20px;
  width: 5rem !important;
  height: 5rem !important;
  font-size: 1.5rem !important;
}


.exp_mod_class{
  width: 100%;
  flex-wrap: wrap;
}

.exp_mod_class >.link{
  width:33%;
}
.marg-left-10 {
    margin-left: 10px;
}
.marg-up-10 {
    margin-top: 10px;
}
.red-alert {
    color: red;
}

.warning-alert {
    color: orange;
}

.legend rect {
  fill: white;
  stroke: black;
  opacity: 0.618;
}
.search {
  padding: 2%;
  background-color: #f8f9fa;
  float: left;
  width: 17%;
}

.right {
  width: 83%;
  float: right;
  overflow-y: auto;
}

.card {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 1%;
}

.card:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.header {
  text-align: center;
}
.header h1 {
  margin-bottom: 0px;
}

.mgin {
  margin-top: 1%;
}

#filterSec {
  display: none;
}
.hw {
  width: 100%;
  height: 100%;
}
.pieslice-angry {
  fill: red !important;
}
.pieslice-focussed {
  fill: red !important;
}
.pieslice-engaged {
  fill: green !important;
}
.pieslice-neutral {
  fill: green !important;
}
.pieslice-disengaged {
  fill: grey !important;
}
.pieslice-happy {
  fill: lawngreen !important;
}
.pieslice-sad {
  fill: orange !important;
}
.pieslice-thoughtful {
  fill: orange !important;
}
.pieslice-fearful {
  fill: orangered !important;
}
.pieslice-disgusted {
  fill: rgba(247, 0, 255, 0.5) !important;
}
.pieslice-suprised {
  fill: blue !important;
}
.disp-none {
  display: none;
}
#idSessions {
  /* width: 96vw; */
  padding: 10px;
}
.datepicker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.datepicker-space {
  margin: 0px 10px;
  width: 35% !important;
}
.select-item > button#menu-dropdown-name,
.select-item > button#menu-dropdown-room {
  background: white;
  color: black;
  border: 1px solid #ced4da;
  border-radius: 50px;
}

.filters-container {
  display: flex;
  float: right;
  width: 44%;
  /* justify-content: space-around; */
}
.pie-chart-container {
  margin-top: 110px;
  display: flex;
  justify-content: center;
}
.report-container {
  padding: 2%;
  margin: 1%;
  width: 100%;
  border: 2px solid #f2f1f4;
  border-radius: 20px;
}
.no-chart-div {
  display: flex;
  justify-content: center;
  background: #89969ec4;
  color: white;
  padding: 40px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
  margin: 0;
  width: 93vw;
  margin-top: 30px;
}
.mkg {
  color: rgb(35 56 105);
  display: flex;
}
.session-chart {
  font-size: 20px;
  font-weight: bold;
}
.marg-10 {
  margin-left: 10px;
}
.ullist {
  display: flex;
  flex-direction: column;
}

.flex-dispo {
  width: 16%;
  display: flex;
}
.select-item {
  width: 15vw;
  margin: 0px 10px;
}
.form-check-input {
  width: 30vw;
}
.custom-select {
  width: 15vw !important;
}
.marg-100 {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.filteritemparent {
  display: flex;
  justify-content: space-around;
  width: 70vw;
}
.disp-flex-left {
  display: flex;
  flex-direction: row-reverse;
}

.hide {
  display: none !important;
}
.hide1 {
  display: none !important;
}

.pagination {
  justify-content: center;
}
#enablefilter {
  margin-left: 10px;
}
.form-k {
  margin-left: 25px;
}

.export_button {
  width: 20% !important;
}

.message_export_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.export_spinner {
  margin: 20px;
  width: 5rem !important;
  height: 5rem !important;
  font-size: 1.5rem !important;
}

.exp_mod_class {
  width: 100%;
  flex-wrap: wrap;
}

.exp_mod_class > .link {
  width: 33%;
}

.analytics {
  cursor: pointer;
}

.analytics:hover {
  color: #17a2b8;
}

/* .modal-content{
    width: 1200px !important;
    max-width: 2200px !important    ;
    height: 60vh !important;
    max-height: 60vh !important;

} */

.modal::-webkit-scrollbar {
  width: 0 !important;
}
.modal-header-container {
  display: flex;
  width: 150%;
  justify-content: space-between;
  align-items: center;
}
.modal-header-container > p {
  font-size: 1rem;
  font-weight: bold;
  color: #111;
}

.modal-header-container > p span {
  font-weight: normal;
  display: block;
}
.modal-height-dialog {
  height: 95%;
  min-width: 90%;
}

.modal-height {
  height: 95%;
  min-width: 90%;
  overflow-y: scroll;
}

.modal-height::-webkit-scrollbar {
  width: 10px;
}
.modal-height::-webkit-scrollbar-thumb {
  background-color: rgb(190, 190, 190);
  border-radius: 5px;
}

.modal-height::-webkit-scrollbar-track {
  background: #f7f7f7;
}

.view-details-btn {
  width: 5.8rem;
  height: 1.8rem;
  font-size: 0.7rem;
}
.activity-thead {
  background-color: #3498db;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
}

.stats-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.stats-container > div {
  width: 300px;
  height: 150px;
  border: solid 2px #fff;
  border-radius: 10px;
  background: #d3ff99;
  box-shadow: 10px 10px 25px 0px rgba(232, 232, 232, 1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  padding-left: 10px;
}
.stats-container > div h1 {
  font-size: 1.3rem;
  margin-bottom: 0;
  color: black;
}

.stats-container > div p {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0;
  color: rgb(0, 0, 24);
}
.stats-container > div:nth-child(2) {
    background: #a7ecee;
  
}
.stats-container > div:nth-child(3) {
    background: #ff5858; 
    
}
.stats-container > div:nth-child(3) h1,.stats-container > div:nth-child(3) p{
  color: #fff;
}


.stats-container > div:nth-child(4) {
    background-color: #c0dbea;
  
}
.stats-container > div:nth-child(1) {
    background: #99dbf5;
}

.collps-btn {
  width: 300px;
}

.webrtc-btn {
  width: 800px;
  padding: 0;
  height: 56px;
}

.webrtc-filter {
  width: 70% !important;
}

.webrtc-filter-btn {
  width: 80%;
}

/* 99DBF5 */

/* A7ECEE */

/* FEA1A1 */
/* C0DBEA */

.link:hover{
  color: white;
  background: #3498db !important;
}
.modal-90w {
  width: 90vw;
  max-width: 90vw;
  max-height: 50vw !important;
}

.pie {
  width: 500px;
  height: 350px;
  cursor: pointer;
}

/* .table{
    width: 600px !important ;
} */

/* .right-cont{
    width: 40% !important;

}

tbody{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
tr{
    width: 42% !important;
}

td{
    width: 70% !important;
} */
.mdl-tbl {
    height: 520px;
    width: 700px;
    display: -moz-groupbox;
    position: relative;
}
.mdl-tbl > tbody {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 450px;
    width: 700px;
    position: absolute;
    
}
.mdl-tbl > tbody::-webkit-scrollbar {
    width: 10px;
  }
  .mdl-tbl > tbody::-webkit-scrollbar-thumb {
    background: rgb(218, 218, 218);
  }
  
  .mdl-tbl > tbody::-webkit-scrollbar-thumb:hover {
    background: rgb(213, 213, 213);
  }
.mdl-tbl tr {
  width: 100%;
  display: inline-table;
  height: 60px;
}

.mdl-tbl td,.mdl-tbl th{
    width: 45% !important;
}
.mdl-tbl td:nth-child(2){
    width: 30% !important;
}
.mdl-tbl th:nth-child(2){
    width: 33% !important;
}
.chart-container-doughnut {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

 .mdl-tbl-dnt,.tbl {
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 auto;
  border: 1px solid #eee;
  color: #111;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 55%; 
}

.mdl-tbl-dnt > thead {
  background-color: #3498db;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  width: 100%;
  display: block;
}
.violated-apps-table > thead tr th{
  padding: 20px ;
  width: 30%;
  font-size: 1rem;
  text-transform: uppercase;
}

.mdl-tbl-dnt > thead tr th:nth-child(3),.mdl-tbl-dnt > thead tr th:nth-child(4){
  padding-left: 18px;
}




.violated-apps-table > tbody tr td{
  padding: 5px 20px !important;
  width: 30% !important;
  border-bottom: solid 1px #ccc;
}
/* .mdl-tbl-dnt > thead tr {
  display: block;
  position: relative;

  
}
.mdl-tbl-dnt > thead tr th{
  padding-top: 22px;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.mdl-tbl-dnt td:nth-child(1), 
.mdl-tbl-dnt th:nth-child(1){
    min-width: 200px;
}
.mdl-tbl-dnt td:nth-child(2),
.mdl-tbl-dnt th:nth-child(2) {
    min-width: 200px;
}
.mdl-tbl-dnt td:nth-child(3),
.mdl-tbl-dnt th:nth-child(3) {
    min-width: 200px;
}
.mdl-tbl-dnt td:nth-child(4),
.mdl-tbl-dnt th:nth-child(4) {
    min-width: 200px;
}

.mdl-tbl-dnt th,.mdl-tbl td {
    padding: 5px;
    text-align: left;
} */

.mdl-tbl-dnt > tbody  {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 430px;
  width: 100%;
  position: absolute;
}
/* .mdl-tbl-dnt > tbody tr:nth-child(2n) {
    /* background-color: #dddddd; */
    /* border-bottom: solid 1px #ccc;
    border-top: solid 1px #ccc;
} 
 */

.violated-apps-table {
  width: 100% !important;
  height: 500px !important;
  margin-left: auto;
  margin-right: auto;
  display: -moz-groupbox;
  position: relative
} 

/* .violated-apps-table > tbody {
  width: 500px !important;
}
.mdl-tbl > tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 390px;
  width: 100%;
  position: absolute;
}
.mdl-tbl > tbody::-webkit-scrollbar {
  width: 8px;
}
.mdl-tbl > tbody::-webkit-scrollbar-thumb {
  background: rgb(218, 218, 218);
  border-radius: 5px;
}

.mdl-tbl > tbody::-webkit-scrollbar-thumb:hover {
  background: rgb(213, 213, 213);
}
.mdl-tbl tr {
  width: 100%;
  display: inline-table;
  height: 60px;
}

.mdl-tbl td,
.mdl-tbl th {
  width: 45% !important;
  padding: 10px; 
	border: 1px solid #ccc; 
	text-align: left; 
	font-size: 13px;
}
*/
/* .mdl-tbl th{

  background: #3498db;
    color: white;
    font-weight: bold;
}  */
.pre-info {
  font-size: 1rem;
  color: #111;
}

canvas {
  cursor: pointer;
}

.app-time-heading {
  margin: 40px 0 !important;
  text-transform: uppercase;
  font-size: 1.5px;
  /* font-weight: bold; */
}

.tbl > thead{
  background-color: #3498db;
  color: #ffffff;
}
.tbl{
  width: 60% !important;
  height: 520px;
  display: -moz-groupbox;
  position: relative
  /* height: 450px !important; */
}

.tbl > tbody{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 385px;
  width: 100%;
  position: absolute;
}

.tbl > thead tr th{
  padding: 20px ;
  width:50%;
  font-size: 1rem;
  text-transform: uppercase;
}
.tbl > thead tr th:nth-child(2){
  padding-left: 15px !important;
}
.tbl > tbody tr td{
  display: inline-block;
  padding: 10px 20px ;
  width: 50%;
  /* min-width: 70%; */
  border-bottom: solid 1px #ccc;
}
.tbl > tbody tr{
  display: block;
}
/* .tbl > tbody tr td:nth-child(1){
  float: left;
} 
.tbl > tbody tr td:nth-child(2){
  float: right; */
/* }  */
.bar-chart-container{
    width: 80%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.app-time-heading{
    font-size: 1.2rem;
    color: #111;
    text-align: center;
    margin-bottom: 10px;
}
.app-time-container{
    margin-top: 20px;
}

.post-text{
    text-align: right;
    font-size: 0.8rem;
    padding-top: 20px;
    
}

.activity-filters {
    display: flex;
    justify-content: space-around;
    margin-top: 32px;
}

h1 {
    margin: 0;
    line-height: 2;
    text-align: center;
}
h2 {
    margin: 0 0 0.5em;
    font-weight: normal;
}
.stats-input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
.row {
    display: flex;
}
.row .col {
    flex: 1 1;
}
.row .col:last-child {
    margin-left: 1em;
    margin-right: 1em;
}

.tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}
.tab {
    width: 100%;
    color: white;
    overflow: hidden;
}
.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #2c3e50;
    font-weight: bold;
    cursor: pointer;
}
.tab-label:hover {
    background: #1a252f;
}
.tab-label::after {
    content: "\276F";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
}
.tab-content-stats {
    max-height: 0;
    padding: 0 1em;
    color: gray;
    background: white;
    transition: all 0.35s;
    font-size: 15px;
    font-weight: bold;
}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
}
.tab-close:hover {
    background: #1a252f;
}

input:checked + .tab-label {
    background: #1a252f;
}
input:checked + .tab-label::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
input:checked ~ .tab-content-stats {
    max-height: 100vh;
    padding: 1em;
}
.stats-error-red{
    color:brown;
}
.stats-error-green {
    color:darkgreen;
}
.stats-error-dark {
    color:sienna;
}
.stats-error-date {
    color: orange;
}


.margin-left-10 {
  margin-left: 20px;
}

.search-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 21%;
}

.radio-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radio-container>div {
  display: flex;
  font-size: 0.8rem;
  font-weight: 500;
  color: #111;
}





body {
  font-family: Arial, sans-serif;
}

.permission-table {
  border-collapse: collapse;
  width: 100%;
}

.table-data {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

input[type="radio"] {
  margin-right: 5px;
}

input[disabled]+label {
  color: #aaa;
}

.count-span{
  font-size: 1rem;
  font-family: 'Courier New', Courier, monospace;
  color: #4c4c4c;

}

.t-head{
  background-color:   #26a3db;
  color: #fff;
  border: none;
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}
.ws-err{
  color: red;
}
.sc-fqkvVR{
    min-width: 72px !important;
}
.ql-container {
    height: 135px !important;
  }

  .type-catgory {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .type-catgory > input{
        margin-bottom: 0 !important;
  }

  .type-catgory label {
    margin-right: 5px;
  }
  .type-catgory:nth-child(1) {
    margin-right: 30px;
  }

  .type-container {
    display: flex;
  }

  .urgent-tooltip {
    cursor: pointer;
    margin-left: 10px;
  }

  .lds-spinner {
    color: black;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    -webkit-transform-origin: 40px 40px;
            transform-origin: 40px 40px;
    -webkit-animation: lds-spinner 1.2s linear infinite;
            animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: black;
  }
  .lds-spinner div:nth-child(1) {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg);
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg);
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg);
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  @-webkit-keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
/* Style for the application names */
.app-name {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  .app_container {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .app-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Style for the table header */
  .table-header {
    background-color: #5fa2e0;
    color: #fff;
    padding: 10px;
    text-align: center;
    font-size: 16px;
  }
  
  /* Style for the table rows */
  .table-row:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Style for the table cells */
  .table-row td:nth-child(n + 2) {
    text-align: center;
  }
  
  /* Hide default radio buttons */
  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
  }
  
  /* Create custom radio button style */
  input[type="radio"] {
    width: 20px;
    height: 20px;
    border: 2px solid #5fa2e0;
    /* Border color for unchecked radio buttons (skyblue) */
    margin: 0;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    /* Add pointer cursor style */
    transition: background-color 0.3s ease, border-color 0.3s ease;
    /* Add transition for animation */
  }
  
  /* Customize radio button appearance when checked */
  input[type="radio"]:checked {
    background-color: #5fa2e0;
    /* Background color for checked radio buttons (skyblue) */
    border-color: #5fa2e0;
    /* Border color for checked radio buttons (skyblue) */
  }
  
  /* Add inner circle (tick) for checked state */
  input[type="radio"]:checked::before {
    content: "\2714";
    /* Unicode character for checkmark (tick) */
    width: 14px;
    height: 14px;
    color: #fff;
    /* Color of the tick mark (white) */
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    /* Start with a scaled down tick mark for animation */
    opacity: 0;
    /* Start with opacity set to 0 for fade-in effect */
    transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
    transition: transform 0.2s ease, opacity 0.2s ease;
    transition: transform 0.2s ease, opacity 0.2s ease, -webkit-transform 0.2s ease;
    /* Add transition for animation */
  }
  
  /* Animation to fade-in and scale up the tick mark when checked */
  input[type="radio"]:checked::before {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  /* WarningMessage.css */
  .warning-container {
    display: flex;
    /* Use flexbox to make the items side by side */
    align-items: center;
    /* Vertically center items */
    justify-content: space-between;
    /* Distribute items with space between them */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  
  .warning-message {
    color: #333;
    padding: 10px;
  
    font-size: 16px;
  }
  
  .submit-button {
    padding: 8px 16px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .card {
    height: 400px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 28%;
  }
  
  .card:hover {
    -webkit-transform: none !important;
            transform: none !important;
  }
  
  .card-header {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: 500;
    color: #111;
    text-align: center;
    text-transform: uppercase !important;
    padding-bottom: 8px !important;
    border-bottom: solid 2px #26a3db;
    
    width: 100%;
  }
  .card-body {
    padding: 0 !important;
    width: 100%;
  
    overflow-y: scroll;
  }
  .card-body::-webkit-scrollbar {
    width: 8px;
    background-color: #f6f6f6;
  }
  
  .card-body::-webkit-scrollbar-thumb {
    background-color: rgb(210, 210, 210);
    border-radius: 5px;
  }
  .card-body::-webkit-scrollbar-thumb:hover {
    background-color: grey;
  }
  .list-group-item {
    padding-left: 10px !important;
    font-size: 0.9rem;
    cursor: pointer;
    color: rgb(97, 97, 97);
  }
  .list-group-item:hover {
    background: #f9f9f9;
    color: #000000;
    
  }
  
  
  @media (max-width:900px){
    .app_container{
      flex-direction: column;
      width: 100%;
    } 
  
    .card{
      width: 90%;
    }
  }
  
  
/* Customize the style of the context menu */
.react-contextmenu {
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px 0;
  min-width: 150px;
  font-size: 14px;
  z-index: 1;
}

/* Customize the style of context menu items */
.react-contextmenu-item {
  padding: 8px 15px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.react-contextmenu-item:hover {
  background-color: #f0f0f0;
}


/* Dynamically set the position of the context menu based on the provided coordinates */
.react-contextmenu.react-contextmenu--visible {
  position: fixed;
  top: var(--contextmenu-y);
  left: var(--contextmenu-x);
}

/* Style for the application names */
.app-name {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  .app_container {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .app-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Style for the table header */
  .table-header {
    background-color: #5fa2e0;
    color: #fff;
    padding: 10px;
    text-align: center;
    font-size: 16px;
  }
  
  /* Style for the table rows */
  .table-row:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Style for the table cells */
  .table-row td:nth-child(n + 2) {
    text-align: center;
  }
  
  /* Hide default radio buttons */
  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
  }
  
  /* Create custom radio button style */
  input[type="radio"] {
    width: 20px;
    height: 20px;
    border: 2px solid #5fa2e0;
    /* Border color for unchecked radio buttons (skyblue) */
    margin: 0;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    /* Add pointer cursor style */
    transition: background-color 0.3s ease, border-color 0.3s ease;
    /* Add transition for animation */
  }
  
  /* Customize radio button appearance when checked */
  input[type="radio"]:checked {
    background-color: #5fa2e0;
    /* Background color for checked radio buttons (skyblue) */
    border-color: #5fa2e0;
    /* Border color for checked radio buttons (skyblue) */
  }
  
  /* Add inner circle (tick) for checked state */
  input[type="radio"]:checked::before {
    content: "\2714";
    /* Unicode character for checkmark (tick) */
    width: 14px;
    height: 14px;
    color: #fff;
    /* Color of the tick mark (white) */
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    /* Start with a scaled down tick mark for animation */
    opacity: 0;
    /* Start with opacity set to 0 for fade-in effect */
    transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
    transition: transform 0.2s ease, opacity 0.2s ease;
    transition: transform 0.2s ease, opacity 0.2s ease, -webkit-transform 0.2s ease;
    /* Add transition for animation */
  }
  
  /* Animation to fade-in and scale up the tick mark when checked */
  input[type="radio"]:checked::before {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  /* WarningMessage.css */
  .warning-container {
    display: flex;
    /* Use flexbox to make the items side by side */
    align-items: center;
    /* Vertically center items */
    justify-content: space-between;
    /* Distribute items with space between them */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  
  .warning-message {
    color: #333;
    padding: 10px;
  
    font-size: 16px;
  }
  
  .submit-button {
    padding: 8px 16px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .card {
    height: 400px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 28%;
  }
  
  .card:hover {
    -webkit-transform: none !important;
            transform: none !important;
  }
  
  .card-header {
    background-color: #ffffff !important;
    font-size: 1rem;
    font-weight: 500;
    color: #111;
    text-align: center;
    text-transform: uppercase !important;
    padding-bottom: 8px !important;
    border-bottom: solid 2px #26a3db;
    
    width: 100%;
  }
  .card-body {
    padding: 0 !important;
    width: 100%;
  
    overflow-y: scroll;
  }
  .card-body::-webkit-scrollbar {
    width: 8px;
    background-color: #f6f6f6;
  }
  
  .card-body::-webkit-scrollbar-thumb {
    background-color: rgb(210, 210, 210);
    border-radius: 5px;
  }
  .card-body::-webkit-scrollbar-thumb:hover {
    background-color: grey;
  }
  .list-group-item {
    padding-left: 10px !important;
    font-size: 0.9rem;
    cursor: pointer;
    color: rgb(97, 97, 97);
  }
  .list-group-item:hover {
    background: #f9f9f9;
    color: #000000;
    
  }
  
  
  @media (max-width:900px){
    .app_container{
      flex-direction: column;
      width: 100%;
    } 
  
    .card{
      width: 90%;
    }
  }
  
  
/* Customize the style of the context menu */
.react-contextmenu {
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px 0;
  min-width: 150px;
  font-size: 14px;
  z-index: 1;
}

/* Customize the style of context menu items */
.react-contextmenu-item {
  padding: 8px 15px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.react-contextmenu-item:hover {
  background-color: #f0f0f0;
}


/* Dynamically set the position of the context menu based on the provided coordinates */
.react-contextmenu.react-contextmenu--visible {
  position: fixed;
  top: var(--contextmenu-y);
  left: var(--contextmenu-x);
}

.legend rect {
  fill: white;
  stroke: black;
  opacity: 0.618;
}
.search {
  padding: 2%;
  background-color: #f8f9fa;
  float: left;
  width: 17%;
}

.right {
  width: 83%;
  float: right;
  overflow-y: auto;
}

.card {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 1%;
}

.card:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.header {
  text-align: center;
}
.header h1 {
  margin-bottom: 0px;
}

.mgin {
  margin-top: 1%;
}

#filterSec {
  display: none;
}
.hw {
  width: 100%;
  height: 100%;
}
.pieslice-angry {
  fill: red !important;
}
.pieslice-focussed {
  fill: red !important;
}
.pieslice-engaged {
  fill: green !important;
}
.pieslice-neutral {
  fill: green !important;
}
.pieslice-disengaged {
  fill: grey !important;
}
.pieslice-happy {
  fill: lawngreen !important;
}
.pieslice-sad {
  fill: orange !important;
}
.pieslice-thoughtful {
  fill: orange !important;
}
.pieslice-fearful {
  fill: orangered !important;
}
.pieslice-disgusted {
  fill: rgba(247, 0, 255, 0.5) !important;
}
.pieslice-suprised {
  fill: blue !important;
}
.disp-none {
  display: none;
}
#idSessions {
  /* width: 96vw; */
  padding: 10px;
}
.datepicker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.datepicker-space {
  margin: 0px 10px;
  width: 35% !important;
}
.select-item > button#menu-dropdown-name,
.select-item > button#menu-dropdown-room {
  background: white;
  color: black;
  border: 1px solid #ced4da;
  border-radius: 50px;
}

.filters-container {
  display: flex;
  float: right;
  width: 44%;
  /* justify-content: space-around; */
}
.pie-chart-container {
  margin-top: 110px;
  display: flex;
  justify-content: center;
}
.report-container {
  padding: 2%;
  margin: 1%;
  width: 100%;
  border: 2px solid #f2f1f4;
  border-radius: 20px;
}
.no-chart-div {
  display: flex;
  justify-content: center;
  background: #89969ec4;
  color: white;
  padding: 40px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
  margin: 0;
  width: 93vw;
  margin-top: 30px;
}
.mkg {
  color: rgb(35 56 105);
  display: flex;
}
.session-chart {
  font-size: 20px;
  font-weight: bold;
}
.marg-10 {
  margin-left: 10px;
}
.ullist {
  display: flex;
  flex-direction: column;
}

.flex-dispo {
  width: 16%;
  display: flex;
}
.select-item {
  width: 15vw;
  margin: 0px 10px;
}
.form-check-input {
  width: 30vw;
}
.custom-select {
  width: 15vw !important;
}
.marg-100 {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.filteritemparent {
  display: flex;
  justify-content: space-around;
  width: 70vw;
}
.disp-flex-left {
  display: flex;
  flex-direction: row-reverse;
}

.hide {
  display: none !important;
}
.hide1 {
  display: none !important;
}

.pagination {
  justify-content: center;
}
#enablefilter {
  margin-left: 10px;
}
.form-k {
  margin-left: 25px;
}

.export_button {
  width: 20% !important;
}

.message_export_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.export_spinner {
  margin: 20px;
  width: 5rem !important;
  height: 5rem !important;
  font-size: 1.5rem !important;
}

.exp_mod_class {
  width: 100%;
  flex-wrap: wrap;
}

.exp_mod_class > .link {
  width: 33%;
}

.analytics {
  cursor: pointer;
}

.analytics:hover {
  color: #17a2b8;
}

/* .modal-content{
    width: 1200px !important;
    max-width: 2200px !important    ;
    height: 60vh !important;
    max-height: 60vh !important;

} */

.modal::-webkit-scrollbar {
  width: 0 !important;
}
.modal-header-container {
  display: flex;
  width: 150%;
  justify-content: space-between;
  align-items: center;
}
.modal-header-container > p {
  font-size: 1rem;
  font-weight: bold;
  color: #111;
}

.modal-header-container > p span {
  font-weight: normal;
  display: block;
}
.modal-height-dialog {
  height: 95%;
  min-width: 90%;
}

.modal-height {
  height: 95%;
  min-width: 90%;
  overflow-y: scroll;
}

.modal-height::-webkit-scrollbar {
  width: 10px;
}
.modal-height::-webkit-scrollbar-thumb {
  background-color: rgb(190, 190, 190);
  border-radius: 5px;
}

.modal-height::-webkit-scrollbar-track {
  background: #f7f7f7;
}

.view-details-btn {
  width: 5.8rem;
  height: 1.8rem;
  font-size: 0.7rem;
}
.activity-thead {
  background-color: #3498db;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
}

.stats-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.stats-container > div {
  width: 300px;
  height: 150px;
  border: solid 2px #fff;
  border-radius: 10px;
  background: #d3ff99;
  box-shadow: 10px 10px 25px 0px rgba(232, 232, 232, 1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  padding-left: 10px;
}
.stats-container > div h1 {
  font-size: 1.3rem;
  margin-bottom: 0;
  color: black;
}

.stats-container > div p {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0;
  color: rgb(0, 0, 24);
}
.stats-container > div:nth-child(2) {
    background: #a7ecee;
  
}
.stats-container > div:nth-child(3) {
    background: #ff5858; 
    
}
.stats-container > div:nth-child(3) h1,.stats-container > div:nth-child(3) p{
  color: #fff;
}


.stats-container > div:nth-child(4) {
    background-color: #c0dbea;
  
}
.stats-container > div:nth-child(1) {
    background: #99dbf5;
}

.collps-btn {
  width: 300px;
}

.webrtc-btn {
  width: 800px;
  padding: 0;
  height: 56px;
}

.webrtc-filter {
  width: 70% !important;
}

.webrtc-filter-btn {
  width: 80%;
}

/* 99DBF5 */

/* A7ECEE */

/* FEA1A1 */
/* C0DBEA */

.search-user_view {
  display: flex;
  justify-content: space-between;
  align-items: right;
  width: 40%;
}
.add_btn_group {
  width: 225px;
  margin-right: 10px;
}

.group-selected-value input {
    line-height: 1.5;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    width: 100%;
    margin-bottom: 20px !important;
  }
  
  .selected-rooms {
    line-height: 1.5;
    font-size: 1rem;
    background-color: #fff;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    width: 100%;
    margin-bottom: 20px !important;
  }
  
html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	color: #747474;
	font-size: 12px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: .5px;
	font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
	margin: 0;
	font-weight: 400;
	margin-bottom: 8px;
	word-wrap: break-word;
	font-family: 'Muli', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #1E3869;
}

h1 {
	font-size: 42px;
	line-height: 52px;
}

h2 {
	font-size: 32px;
	line-height: 42px;
}

h3 {
	font-size: 28px;
	font-weight: 800;
	line-height: 38px;
}

h4 {
	font-size: 22px;
	line-height: 32px;
}

h5 {
	font-size: 18px;
	line-height: 28px;
}

h6 {
	font-size: 14px;
	line-height: 24px;
}

p {
	font-size: 12px;
	line-height: 20px;
}

a {
	cursor: pointer;
	font-size: 12px;
	line-height: 22px;
	display: inline-block;
}

span {
	display: inline-block;
}

img {
	width: 100%;
	transition: 0.3s ease-in-out;
}

ul {
	margin: 0;
	padding: 0;
}

a:hover {
	text-decoration: none;
}

div {
	margin: 0;
	padding: 0;
}

section {
	z-index: 1;
	padding: 50px 0;
	position: relative;
}

input,textarea,select{
	border: 1px solid #26A3DB;
	border-radius: 5px;
	padding: 6px 7px;
	letter-spacing: .5px;
	font-size: 14px;
	width: 100%;
}
input:focus,textarea:focus{
	outline: none;
}


.di{
	display: inline-block;
}

/*-- Button, Anchor --*/

.link {
	color: #fff;
	border: none;
	font-size: 12px;
	padding: 8px 15px;
	border-radius: 5px;
	background: #26A3DB;
	letter-spacing: 1px;
}

.link:hover {
	background: #1E3869;
}


