.modal-90w {
  width: 90vw;
  max-width: 90vw;
  max-height: 50vw !important;
}

.pie {
  width: 500px;
  height: 350px;
  cursor: pointer;
}

/* .table{
    width: 600px !important ;
} */

/* .right-cont{
    width: 40% !important;

}

tbody{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
tr{
    width: 42% !important;
}

td{
    width: 70% !important;
} */
.mdl-tbl {
    height: 520px;
    width: 700px;
    display: -moz-groupbox;
    position: relative;
}
.mdl-tbl > tbody {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 450px;
    width: 700px;
    position: absolute;
    
}
.mdl-tbl > tbody::-webkit-scrollbar {
    width: 10px;
  }
  .mdl-tbl > tbody::-webkit-scrollbar-thumb {
    background: rgb(218, 218, 218);
  }
  
  .mdl-tbl > tbody::-webkit-scrollbar-thumb:hover {
    background: rgb(213, 213, 213);
  }
.mdl-tbl tr {
  width: 100%;
  display: inline-table;
  height: 60px;
}

.mdl-tbl td,.mdl-tbl th{
    width: 45% !important;
}
.mdl-tbl td:nth-child(2){
    width: 30% !important;
}
.mdl-tbl th:nth-child(2){
    width: 33% !important;
}