#myHeader {
  top: 0;
  width: 99.99%;
  z-index: 99;
  background: #fff;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-name: hdrfix;
  animation-name: hdrfix;
  box-shadow: 0 0 5px #aaaaaa;
  position: relative;
}

@-webkit-keyframes hdrfix {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.icon-img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.flex {
  display: flex;
}

.mr-4 {
  margin-right: 4px;
}

@keyframes hdrfix {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/*-----------------------------------------
  ------------- ( header )
  -----------------------------------------*/
header {
  border-bottom: 1px solid #e2e1e7;
}
header ul {
  display: flex;
  align-items: center;
}
header .left-box ul li {
  flex: 0 0 50%;
  padding: 12px 0;
  text-transform: uppercase;
}
header .left-box .logo {
  padding-right: 15px;
  border-right: 1px solid #e2e1e7;
}
header .left-box h4 {
  margin-bottom: 0;
  padding-left: 15px;
  font-weight: 600;
}
header .center-box a {
  display: flex;
  align-items: center;
}
header .center-box ul {
  justify-content: center;
}
header .center-box ul li a {
  margin: 0 5px;
}
header select {
  color: #747474;
  padding: 6px 12px;
  background: #f9f9fb;
  border-radius: 5px;
}
header .center-box a img {
  width: auto;
  height: 20px;
  margin-right: 7px;
}

header .right-box ul {
  padding: 7px 0;
  justify-content: flex-end;
  border-left: 1px solid #e2e1e7;
}
header .right-box ul li {
  padding: 0 10px;
}
header .right-box .profile {
  display: flex;
  align-items: center;
}
header .right-box .profile img {
  border-radius: 5px;
  max-width: 38px;
  margin-right: 8px;
}

header .right-box .dropdown-toggle {
  background: #26a3db;
  border: none;
  color: #fff;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 5px;
}
header .right-box .dropdown-toggle:hover,
header .right-box .dropdown-toggle[aria-expanded="true"] {
  background: #1e3869;
  outline: none !important;
}
header .right-box .dropdown-menu {
  padding: 5px 10px;
}
header .right-box .dropdown-menu a {
  width: 100%;
}

header .right-box .logout:hover {
  opacity: 0.5;
}

@media (max-width: 1024px) {
  header .left-box h4 {
    font-size: 18px;
    line-height: 32px;
    padding-left: 10px;
  }
  header .left-box .logo {
    padding-right: 10px;
  }
  header .center-box ul li a {
    margin: 0 2px;
  }
  header select {
    padding: 3px 4px;
  }
  header .center-box a img {
    height: 16px;
    margin-right: 3px;
  }
  header .right-box ul li {
    padding: 0 5px;
  }
  header .right-box .dropdown-toggle {
    letter-spacing: 0.5px;
    font-size: 13px;
    padding: 5px 8px;
  }
  header .right-box .profile img {
    margin-right: 5px;
  }
}

@media (max-width: 991px) {
  header .col-md-4 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .center-box {
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    padding: 20px 0;
  }
  header .left-box h4 {
    font-size: 22px;
    line-height: 32px;
    padding-left: 0;
    text-align: center;
  }
  header .left-box .logo {
    text-align: center;
    padding-right: 0;
  }
  header .left-box .logo img {
    max-width: 230px;
  }
  header .center-box a img {
    height: 22px;
    margin-right: 5px;
  }
  header .center-box ul li a {
    margin: 0 10px;
  }
  header select {
    padding: 3px 10px;
  }
  header .right-box ul {
    padding: 12px 0;
    border-left: none;
    justify-content: center;
  }
  #myHeader[style="position: fixed;"] .col-md-4:nth-child(1),
  #myHeader[style="position: fixed;"] .col-md-4:nth-child(2) {
    display: none;
  }
}

@media (max-width: 768px) {
  header .left-box h4 {
    font-size: 18px;
    line-height: 26px;
  }

  header .left-box .logo img {
    max-width: 160px;
  }
  header .right-box .profile img {
    margin-right: 10px;
  }
  header .center-box ul li a {
    margin: 0 5px;
  }
  header .right-box .dropdown-toggle {
    padding: 5px 12px;
  }
}
li {
  list-style: none;
}
