#idSessions {
  padding: 35px;
}

.Collapsible {
  background-color: rgb(148, 154, 156);
}
.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid lightGrey;
  border-top: 0;
  mix-blend-mode: overlay;
}
.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}
.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}
.Collapsible__trigger {
  display: block;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  border: 5px solid white;
  padding: 10px;
  background: rgb(136, 163, 173);
  color: white;
  font-size: 14px;
}
.Collapsible__trigger:after {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}
.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}
.CustomTriggerCSS--open {
  background-color: darkslateblue;
}
.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

.geo-tabs-container {
  height: 60px;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.geo-tabs-container > a {
  font-size: 1.2rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 1px 5px -1px rgba(184, 184, 184, 1);
  -moz-box-shadow: 0px 1px 5px -1px rgba(184, 184, 184, 1);
  box-shadow: 0px 1px 5px -1px rgba(184, 184, 184, 1);
  color: #111;
}
.geo-tabs-container > a:hover {
  color: #111;
}

.active {
  background: #009dd1 !important;
  /* background: linear-gradient(180deg, rgba(0,205,237,1) 0%, rgba(141,239,255,1) 40%, rgba(0,220,255,1) 72%) !important; */

  color: #fff !important;
}
.tab-content{
  width: 98vw !important;

}

.tab-content .active {
  background: transparent !important;
  color: #111 !important;
  width: 100% !important;
}

.nav-item {
  width: 40% !important;
  height: 60px;
}

.text-align-center {
  text-align: center;
}

